import { KeyBindingUtil } from 'draft-js';

/**
 * The list of default valid block types.
 */
const ctrlKey = KeyBindingUtil.usesMacOSHeuristics() ? 'Cmd (⌘)' : 'Ctrl';

export const Block = {
  UNSTYLED: 'unstyled',
  PARAGRAPH: 'unstyled',
  OL: 'ordered-list-item',
  UL: 'unordered-list-item',
  H1: 'header-one',
  H2: 'header-two',
  H3: 'header-three',
  H4: 'header-four',
  H5: 'header-five',
  H6: 'header-six',
  CODE: 'code-block',
  BLOCKQUOTE: 'blockquote',
  ATOMIC: 'atomic',
  CAPTION: 'caption',
};

export const BLOCK_TYPES = {
  gallery: 'gallery',
  image: 'image',
  button: 'button',
  video: 'video',
  embed: 'embed',
};

export const BLOCK_TOOLBAR_SETTINGS = [
  // Available keys: alignment, type, size, href, alt, edit
  {
    alignment: true,
    type: BLOCK_TYPES.image,
    size: true,
    href: true,
    alt: true,
    edit: true,
  },
  {
    alignment: true,
    type: BLOCK_TYPES.video,
    size: true,
  },
  {
    alignment: true,
    type: BLOCK_TYPES.embed,
    size: true,
  },
  {
    type: BLOCK_TYPES.gallery,
    size: true,
    edit: true,
  },
  {
    type: BLOCK_TYPES.button,
    href: true,
    edit: true,
  },
];

export const BLOCK_BUTTONS = [
  {
    label: 'H2',
    style: 'header-two',
    icon: 'custom:heading-2',
    description: 'Heading 2',
  },
  {
    label: 'H3',
    style: 'header-three',
    icon: 'custom:heading-3',
    description: 'Heading 3',
  },
  {
    label: 'H4',
    style: 'header-four',
    icon: 'custom:heading-4',
    description: 'Heading 4',
  },
  {
    label: 'Q',
    style: 'blockquote',
    icon: 'fa:quote-left',
    description: 'Blockquote',
  },
  {
    label: 'UL',
    style: 'unordered-list-item',
    icon: 'fa:list-ul',
    description: 'Unordered list',
  },
  {
    label: 'OL',
    style: 'ordered-list-item',
    icon: 'fa:list-ol',
    description: 'Ordered List',
  },
  {
    label: 'CODE',
    style: 'code-block',
    icon: 'custom:code-block',
    description: 'Code block',
    shortcut: `${ctrlKey} + Shift + J`,
  },
];

export const HYPERLINK = 'hyperlink';

export const BASE_BLOCK_CLASS = 'block';
export const BASE_BLOCK_EMPTY_CLASS = 'block--empty';

export const INLINE_BUTTONS = {
  BOLD: {
    label: 'B',
    style: 'BOLD',
    icon: 'fa:bold',
    description: 'Bold',
    shortcut: `${ctrlKey} + B`,
  },
  ITALIC: {
    label: 'I',
    style: 'ITALIC',
    icon: 'fa:italic',
    description: 'Italic',
    shortcut: `${ctrlKey} + I`,
  },
  UNDERLINE: {
    label: 'U',
    style: 'UNDERLINE',
    icon: 'fa:underline',
    description: 'Underline',
    shortcut: `${ctrlKey} + U`,
  },
  STRIKETHROUGH: {
    label: 'ST',
    style: 'STRIKETHROUGH',
    icon: 'fa:strikethrough',
    description: 'Strikethrough',
    shortcut: `${ctrlKey} + Shift + X`,
  },
  SMALL: {
    label: 'S',
    style: 'SMALL',
    icon: 'custom:small',
    description: 'Small',
  },
  HYPERLINK: {
    label: '#',
    style: HYPERLINK,
    icon: 'fa:link',
    description: 'Link',
    shortcut: `${ctrlKey} + Shift + K`,
  },
  CODE: {
    label: 'INLINE-CODE',
    style: 'CODE',
    icon: 'custom:code-inline',
    description: 'Inline code',
    shortcut: `${ctrlKey} + J`,
  },
  COMMENT: {
    label: 'Comment',
    style: 'COMMENT',
    icon: 'fa:comment',
    description: 'Comment',
    shortcut: `${ctrlKey} + Shift + M`,
    showIconAndLabel: true,
  },
};
export const INLINE_BUTTONS_RIGHT = [
  INLINE_BUTTONS.BOLD,
  INLINE_BUTTONS.ITALIC,
  INLINE_BUTTONS.UNDERLINE,
  INLINE_BUTTONS.STRIKETHROUGH,
  INLINE_BUTTONS.SMALL,
  INLINE_BUTTONS.HYPERLINK,
  INLINE_BUTTONS.CODE,
];
export const INLINE_BUTTONS_LEFT = [INLINE_BUTTONS.COMMENT];

export const ENTITY_TYPES = {
  LINK: 'LINK',
  COMMENT: 'COMMENT',
};

export const TOOLBAR_ARROW_SIZE = 8;

/**
 * The list of custom block styles.
 */

export const CUSTOM_BLOCK_STYLES = {
  SMALL: {
    fontSize: '80%',
  },
  CODE: {
    backgroundColor: '#eee',
    borderRadius: '4px',
    color: '#000',
    fontFamily: 'Consolas, Lucida Console, Courier, monospace',
    fontSize: '95%',
    padding: '0 3px',
  },
  SELECTION: {
    backgroundColor: 'rgb(195, 222, 251)', // do not use hex for queryselector inside BlockToolbarAssistant
    padding: '0.6% 0px',
  },
};

/**
 * The list of mention patterns.
 */

// Source: https://github.com/regexhq/mentions-regex/blob/master/index.js
export const MENTIONS_PATTERN =
  /(^|\n|\s|,|\.|\?|-|~|\+|=|\^|\(|\))(?:(?:@|＠)(?!\/))([a-zA-Z0-9/',._\-&]*)(?:\b(?!@|＠)|$)/g;
export const TWITTER_MENTIONS_PATTERN =
  /(^|\n|\s|,|\.|\?|-|~|\+|=|\^|\(|\))(?:(?:@|＠)(?!\/))([a-zA-Z0-9/_]{1,20})(?:\b(?!@|＠)|$)/g;

export default {
  Block,
  ENTITY_TYPES,
};

export const EDITOR_COLLABORATION_EVENT = {
  setEditorOpenInOtherTab: 'SET_EDITOR_OPEN_IN_OTHER_TAB',
  emitStartedEditingBlock: 'EMIT_STARTED_EDITING_BLOCK',
  emitSolvedBlockConflict: 'SOLVED_BLOCK_CONFLICT',
  emitShareBlockedBlocks: 'EMIT_SHARE_BLOCKED_BLOCKS',
  emitFinishedEditingBlock: 'EMIT_FINISHED_EDITING_BLOCK',
};

export const EDITOR_COLLABORATION_WHISPER = {
  updatedContentItem: 'updatedContentItem',
  finishedEditingBlock: 'finishedEditingBlock',
  savedBlockChanges: 'savedBlockChanges',
  startedEditingBlock: 'startedEditingBlock',
  shareBlockedBlocks: 'shareBlockedBlocks',
};
